import React, { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter , Router, Switch, Route, } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';









import Notfound from './components/Notfound';



const Home = lazy(() => import('./components/Home'));
const Learningblocks = lazy(() => import('./components/Learningblocks'));
const Login = lazy(() => import('./components/Login'));
const Signup = lazy(() => import('./components/Signup'));
const Aboutus = lazy(() => import('./components/Aboutus'));
const Subscription = lazy(() => import('./components/Subscriptionplancharges'));
const Editpersonaldetails = lazy(() => import('./components/Editpersonaldetails'));
const Userprofile = lazy(() => import('./components/Userprofile'));
const Courseview = lazy(() => import('./components/Courseview'));
const Discussion = lazy(() => import('./components/Discussion'));
const Unitreadingmaterial = lazy(() => import('./components/Unitreadingmaterial'));
const Discussionforum = lazy(() => import('./components/Discussionforum'));
const Takethequiz = lazy(() => import('./components/Takethequiz'));
const Certificate = lazy(() => import('./components/Certificate'));
const Glossary = lazy(() => import('./components/Glossary'));
const Newsfeed = lazy(() => import('./components/Newsfeed'));
const Downloadeddocument = lazy(() => import('./components/Downloadeddocument'));
const Event = lazy(() => import('./components/Event'));
const Mentoringcareercounselling = lazy(() => import('./components/Mentoringcareercounselling'));
const Workopportunities = lazy(() => import('./components/workopportunities'));
const Counsellingdetails = lazy(() => import('./components/Counsellingdetails'));
const Confirm = lazy(() => import('./components/Confirm'));
const Forgotpass = lazy(() => import('./components/Forgotpass'));
const Resetpass = lazy(() => import('./components/Resetpass'));
const Changepassword = lazy(() => import('./components/Changepassword'));
const Courseviewdetails = lazy(() => import('./components/Courseviewdetails'));
const Learningblockslist = lazy(() => import('./components/Learningblockslist'));
const Resendactivition = lazy(() => import('./components/Resendactivition'));
const Additional = lazy(() => import('./components/Additional'));
const Organisations = lazy(() => import('./components/Organisations'));
const Eventdetails = lazy(() => import('./components/Eventdetails'));
const Privacypolicy = lazy(() => import('./components/Privacypolicy'));
const Termsconditions = lazy(() => import('./components/Termsc'));
const Faqs = lazy(() => import('./components/Faqs'));
const CancellationPolicy = lazy(() => import('./components/CancellationPolicy'));
const Blockquiz = lazy(() => import('./components/Blockquiz'));
const Modulequizanswer = lazy(() => import('./components/Modulequizanswer'));
const Thankyou = lazy(() => import('./components/Thankyou'));
const Blockquizanswer = lazy(() => import('./components/Blockquizanswer'));
const Sharecertificate = lazy(() => import('./components/Sharecertificate'));
const Additionalcontent = lazy(() => import('./components/Additionalcontent'));
const Contentdetails = lazy(() => import('./components/Contentdetails'));
const Downloadcertificate = lazy(() => import('./components/Downloadcertificate'));



function App() {


  return (


    <BrowserRouter basename="/">
      <Switch>
      

      <Suspense fallback={''}>
        <Route path="/home"><Home /></Route>
        <Route path='/downloadcertificate/:id' render={(props) => <Downloadcertificate {...props} />}></Route>
        <Route exact path="/"><Home /></Route>
        <Route path='/confirm/:id' render={(props) => <Confirm {...props} />}></Route>
        <Route path="/privacy-policy"><Privacypolicy /></Route>
        <Route path="/terms-and-conditions"><Termsconditions /></Route>
        <Route path="/faqs"><Faqs /></Route>
        <Route path="/cancellation-policy"><CancellationPolicy /></Route>
        <Route path="/login"> <Login /> </Route>
        <Route path="/forgotpassword"> <Forgotpass /> </Route>
        <Route path="/changepassword"> <Changepassword /> </Route>
        <Route path='/reset-password/:id/:type' render={(props) => <Resetpass {...props} />}></Route>
        <Route path='/courseview/:subid/:blockid/' render={(props) => <Courseview {...props} />}></Route>
        <Route path='/coursedetails/:id' render={(props) => <Courseviewdetails {...props} />}></Route>
        <Route path="/resend-activationlink"> <Resendactivition /> </Route>
        <Route path="/signup"> <Signup /> </Route>
        <Route path="/aboutus"><Aboutus /></Route>
        <Route path="/learningblockslist"><Learningblockslist /></Route>
        <Route path="/learningblocks/:id/" render={(props) => <Learningblocks {...props} />}></Route>
        <Route path="/subscriptionplan"><Subscription /></Route>
        <Route path="/editpersonaldetails"><Editpersonaldetails /></Route>
        <Route path="/userprofile"><Userprofile /></Route>
        <Route path="/discussion"><Discussion /></Route>
        <Route path='/unitreadingmaterial/:subsId/:blockId/:moduleId/:unitId' render={(props) => <Unitreadingmaterial {...props} />}></Route>
        <Route path='/takethequiz/:subsId/:blockId/:moduleId/:mappingId/:confusionId' render={(props) => <Takethequiz {...props} />}></Route>
        <Route path='/blockquiz/:subsId/:blockId/:newId' render={(props) => <Blockquiz {...props} />}></Route>
        <Route path='/discussionforum/:subscription_id/:block_id/:module_id/' render={(props) => <Discussionforum {...props} />}></Route>
        <Route path='/additional/:subscription_id/:block_id/:module_id/:material_id/' render={(props) => <Additional {...props} />}></Route>
        <Route path='/certificate/:subscription_id' render={(props) => <Certificate {...props} />}></Route>
        <Route path='/block-certificate/:block_name/:name/:subscription_id' render={(props) => <Sharecertificate {...props} />}></Route>
        <Route path='/glossary/:subsId/:blockId/' render={(props) => <Glossary {...props} />}></Route>
        <Route path='/contents/:subsId/:blockId/' render={(props) => <Additionalcontent {...props} />}></Route>
        <Route path='/contentdetails/:subsId/:blockId/:index/' render={(props) => <Contentdetails {...props} />}></Route>
        <Route path='/newsfeed/:subsId/:blockId/' render={(props) => <Newsfeed {...props} />}></Route>
        <Route path='/documents/:subsId/:blockId/' render={(props) => <Downloadeddocument {...props} />}></Route>
        <Route path='/volunteering/:subsId/:blockId/' render={(props) => <Workopportunities {...props} />}></Route>
        <Route path='/events/:subsId/:blockId/' render={(props) => <Event {...props} />}></Route>
        <Route path='/mentoring/:subsId/:blockId/' render={(props) => <Mentoringcareercounselling {...props} />}></Route>
        <Route path='/counsellingdetails/:subsId/:blockId/:lstId' render={(props) => <Counsellingdetails {...props} />}></Route>
        <Route path='/organisations/:subsId/:blockId/' render={(props) => <Organisations {...props} />}></Route>
        <Route path='/eventdetails/:subsId/:blockId/:eventId' render={(props) => <Eventdetails {...props} />}></Route>
        <Route path='/module-quiz-answer/:subsId/:blockId/:moduleId/:mappingId' render={(props) => <Modulequizanswer {...props} />}></Route>
        <Route path='/block-quiz-answer/:subsId/:blockId/:idjust' render={(props) => <Blockquizanswer {...props} />}></Route>
        <Route path='/thankyou/:subsId/:blockId/:newId/:confusionId/:status' render={(props) => <Thankyou {...props} />}></Route>
        {/* <Route path="*"><Notfound /></Route> */}
        </Suspense>
      </Switch>
    </BrowserRouter>

  );
}

export default App;
