import React,{Component, lazy, Suspense } from 'react';
import notfound from './images/not-found.png';

const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('./Footer')); 

class Notfound extends Component {
    render() {
        return (
            <div>
                <Suspense fallback={''}>
                  <Header/>
                </Suspense>

                <div className="full-width not-found-page">
                    <img src={notfound}/>
                    <h1><b>Content Coming Soon</b></h1>
                </div>


                <Suspense fallback={''}>
                <Footer showhidenewsletter={false}/>
                </Suspense>
                 


            </div>
        );
    }
}

export default Notfound;
